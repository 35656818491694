import { render, staticRenderFns } from "./editModal.vue?vue&type=template&id=164f1aff&scoped=true"
import script from "./editModal.vue?vue&type=script&lang=js"
export * from "./editModal.vue?vue&type=script&lang=js"
import style0 from "./editModal.vue?vue&type=style&index=0&id=164f1aff&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "164f1aff",
  null
  
)

export default component.exports