<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="774"
    :closable="false"
    centered
    :maskClosable="false"
    :footer="null"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>选择活动模板
      </span>
    </div>
    <div class="cancelContent">
<!--      <a-input-->
<!--        class="searchInput"-->
<!--        v-model="modelName"-->
<!--        @keydown.enter="search"-->
<!--        placeholder="请输入模板名称"-->
<!--      >-->
<!--        <i class="iconfont icon-shouye-sousuo" slot="suffix" @click="search"/>-->
<!--      </a-input>-->
      <div class="modelBox">
        <div
          v-for="(item,index) in list"
          :key="index"
          :class="cherkId == item.id ? 'listItemCherk' : 'listItem'"
          @click="cherkModalItem(item)"
        >
<!--          <div class="ImgBox">-->
<!--            <img :src=item.templatePicUrl alt="">-->
<!--          </div>-->
<!--          <p class="name">{{item.templateName}}</p>-->
          <p class="firstTxt">[{{item.activityTypeName}}]{{item.templateName}}</p>
          <p class="time">有效期：{{item.validity}}</p>
          <div class="zcBox">
            <p class="zc" @click.stop="zcDetail(item)">政策详情</p>
          </div>
        </div>
      </div>
      <div class="noList" v-if="list.length <= 0">
        <img src="@/assets/order/noList.png" alt />
        <p>暂无数据，请试试其他筛选条件~</p>
      </div>
      <div class="btnGroup">
        <h-btn
          class="btn"
          :width="120"
          :height="40"
          fontSize="16"
          content="自定义海报"
          background="#fff"
          level="1"
          style="background:#fff;border:1px solid #00AAA6;color: #00AAA6;text-shadow: none"
          borderRadius="4"
          border="none"
          @click="openZDY"
        >
        </h-btn>
        <h-btn
          v-if="list.length > 0"
          class="btn"
          :width="120"
          :height="40"
          fontSize="16"
          content="预览海报"
          background="#00AAA6"
          level="1"
          style="background:#00AAA6;border:none;margin-left: 24px;text-shadow: none"
          borderRadius="4"
          border="none"
          @click="view(item)"
        >
        </h-btn>
      </div>
<!--      <div class="footer-pager" v-if="list.length > 0">-->
<!--        <Pagination :pager="pager" @query="getData('pageChange')" />-->
<!--      </div>-->

    </div>
  </a-modal>
</template>

<script>
import service from "@/utils/request";
import { findPosterTemplatePage } from "@/views/monopolyShopActivity/creatStoreActivities/api";
export default {
  name:'addUserSource-modal',
  data(){
    return{
      editItem:{}, // 选中的可以编辑的
      cherkId:'',
      list:[],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      modelName:''
    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
    shopInfoId: {
      type: String,
      default: ""
    },
    activityTypeId: {
      type: String,
      default: ""
    },
    posterTemplateId: {
      type: String,
      default: ""
    },
    modalItem: {
      type: Object,
      default: () => {}
    },

  },
  watch: {
    visible: {
      immediate: true,
      handler(newValue) {
       if(newValue) {
         if(this.modalItem){
           this.editItem=this.modalItem.length>0?this.modalItem[0]:{}
         }else{
           this.editItem = {}
         }
         if(this.posterTemplateId){
           this.cherkId = this.posterTemplateId
         }else{
           this.cherkId = ''
         }
         this.getData()
       }else{
         this.list=[]
         this.cherkId = ''
         this.editItem = {}
       }
      }
    },
  },
  mounted(){
    // this.getData()
  },
  methods:{
    // 自定义海报打开弹窗
    openZDY(){
      this.$emit("openZDY",);
    },
    // 预览海报 选中的海报数据
    view(){
      this.$emit("viewOk", this.editItem);
    },
    // 政策详情
    zcDetail(item){
      // window.open(item.activityPolicyUrl,"_blank")
      this.$emit("zcDetail11", item.activityPolicyUrl);

    },
    // 获取模板列表
    getData(){
      let data = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        templateName: this.modelName,
        shopInfoId:this.shopInfoId,
        activityTypeId:this.activityTypeId
      }
      findPosterTemplatePage(data).then(res => {
        if(res.data.code == 0) {
          this.list = res.data.data.content
          this.pager.count = res.data.data.totalPage
        }
      })
    },
    // 选中模板 复制id
    cherkModalItem(item){
      this.cherkId = item.id
      this.editItem = item
    },
    //搜索按钮事件
    search() {
      this.pager.pageNo = 1;
      this.getData();
    },
    handleOk() {
      this.$emit('update:visible',false)
      this.$emit("cancelReasonHandOk", this.editItem);

    },
    cancle(){
      this.$emit('update:visible',false)
    },

  }
}
</script>
<style lang="less" scoped>
/deep/ .cancelName{
  .ant-modal-content{
    text-align:center;
    border-radius: 10px;
    .ant-modal-body{
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 24px 24px 16px 24px;
      .cancelContent{
        display: flex;
        flex-wrap: wrap;
        width: 778px;
        flex-direction: column;
        justify-content: flex-start;
        .searchInput {
          width: 360px;
          height: 32px;
          background: #FFFFFF;
          border-radius: 4px;
          //border: 1px solid #EEEEEE;
          margin-left: 24px;
          i {
            color: #AAAAAA;
          }
        }
        .modelBox {
          margin-top: 16px;
          display: flex;
          justify-content: flex-start;
          //align-items: center;
          flex-wrap: wrap;
          margin-left: 12px;
          min-height: 320px;
          .listItem {
            cursor: pointer;
            margin-bottom: 16px;
            margin-left: 12px;
            width: 359px;
            height: 115px;
            background: linear-gradient(90deg, #EEF9F9 0%, #FBFBFB 100%);
            border-radius: 10px;
            padding: 14px 16px;
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            .firstTxt {

              height: 21px;
              line-height: 21px;
              text-align: left;
              font-size: 15px;
              font-weight: 500;
              color: #262626;
            }
            .time{
              margin-top: 8px;
              text-align: left;
              height: 18px;
              font-size: 13px;
              font-weight: 400;
              color: #777777;
              line-height: 18px;
            }
            .zcBox{
              margin-top: 8px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .zc{
                width: 72px;
                height: 32px;
                display: flex;
                border-radius: 5px;
                border: 1px solid #00AAA6;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 400;
                color: #00AAA6;
              }


            }
          }
          .listItemCherk {
            padding: 14px 16px;
            cursor: pointer;
            margin-bottom: 16px;
            margin-left: 12px;
            width: 359px;
            height: 115px;
            background: linear-gradient(90deg, #54D6D2 0%, #00AAA6 100%);
            border-radius: 10px;
            .firstTxt {
              text-align: left;
              font-size: 15px;
              font-weight: 500;
              color: #fff;
              line-height: 21px;
              height: 21px;
            }
            .time{
              margin-top: 8px;
              text-align: left;
              height: 18px;
              font-size: 13px;
              font-weight: 400;
              color: #fff;
              line-height: 18px;
            }
            .zcBox{
              margin-top: 8px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .zc{
                width: 72px;
                height: 32px;
                display: flex;
                border-radius: 5px;
                border: 1px solid #fff;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 400;
                color: #fff;
              }


            }
          }
        }
        .footer-pager {
          margin-top: 4px;
          margin-right: 14px;
        }
        .noList{
          height:260px;
          background: #fff;
          margin: 16px auto 16px;
          padding-bottom: 24px;
          color: #777;
          text-align: center;
          //width: 100%;
          img{
            display: inline-block;
            width: 160px;
            height: 120px;
            margin:48px auto 16px;
          }
          p{
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #777777;
            line-height: 20px;
          }
        }

        .clickBtn{
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 16px;
          cursor: pointer;
          font-size: 13px;
          font-weight: 400;
        }
        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          //border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 16px;
          border: none;
          font-size: 13px;
          font-weight: 400;
        }
      }
      .cancelInfo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        margin-bottom: 24px;
        text-align: left;
        overflow: hidden;
        .cancleZi{
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          margin-right: 8px;
          text-align: right;
        }
        .ant-input{
          width: 300px;
          height:32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .ant-input-affix-wrapper{
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        padding-bottom:10px;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing:1px;
          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }
    p{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }
    .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:0px 0 24px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        font-size: 16px;
        color:#777;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
        margin-left:24px;
      }
    }
  }
}
</style>


