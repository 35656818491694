import { publicPath } from "@/common/constant";
import templateListModal from "@/views/monopolyShopActivity/creatStoreActivities/components/templateListModal";
import editModal from "@/views/monopolyShopActivity/creatStoreActivities/components/editModal";
import previewPoster from './components/previewPoster'
import customPoster from './components/customPoster'
import zcDetail from './components/zcDetail'
import axios from "axios";
// import { getDLlnumSeach } from "@/views/storeActivities/addOredit/api";
import { SalesShopInfoList } from "@/views/confirmAgain/api";
import { getYxList } from "@/views/storeActivities/api";
import moment from "moment";
import ImageCompressor from 'js-image-compressor'
import { getMatklByShop } from "@/views/storeActivities/editOredit/api";
import {
  createOrEdit,
  findFollowPeople,
  findTemplateDetail,
  getDetail,
  makeImg
} from "@/views/monopolyShopActivity/creatStoreActivities/api";

export default {
  components: {
    templateListModal,
    editModal,
    previewPoster,
    customPoster,
    zcDetail,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  data() {
    return {
      sortList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/campaignList",
          name: "campaignList",
          title: "专卖店活动"
        },
        {
          path: "/creatStoreActivities",
          name: "creatStoreActivities",
          title:  (this.$route.query.id? "编辑" : "创建")+"专卖店活动"
        }
      ],
      param: {
          sortField: "", //排序字段，sale销量，onlineDate上架时间
          sortType: "desc", //排序类型，asc正序 或 desc倒序
          pageNum: 1, //页码
          pageSize: 20, //每页条数
      },
      pageLoading: false,
      datalist:[],
      activefileList: [],
      activefileId: [],
      // 模板分类
      templateOptions: [
        {
          id: 1,
          name:'通用模板'
        },
        {
          id: 2,
          name:'自定义海报'
        },
      ],
      uploadUrl: publicPath + "/comm/method=uploadFile.nd",
      fileList: [], // 自定义模板附件
      modelfileId: '',  // 自定义模板id
      previewVisible: false, // 自定义预览的弹窗
      previewImage: '', // 自定义预览的图片
      modelViseible: false,
      downloadUrl: `${process.env.BASE_URL}downLoadTemplate/modelImg.jpg`, // 自定义的可供下载的
      visibleModal: false,// 通用选择模板弹窗
      modalItem: [], // 弹窗页面选中数据进行页面通用
      modelViseibleTy: false, // 通用预览
      editModal: false, // 通用海报的弹窗
      shopList: [],  // 门店数组
      activityTypeList: [], //活动类型数组
      // 接口传参数据
      form: {
        activityId: '',// 活动id 新增不填
        shopInfoId: undefined, // 门店id
        activityTypeId: undefined, // 活动类别id
        activityTheme: '', // 主题
        activityStartTime: '', // 开始时间
        activityEndTime: '', // 结束时间
        modelType: 1, // 默认模板分类为通用
        posterId: '',//海报id
        bussiness:'',
        model:undefined
      },
      visiblePoster:false,
      posterItem:{},
      exclusiveActivitySalesDTOList: [],// 物料组数据 (销售计划(万元))
      // 海报数据
      posterDTO: {
        posterId: '', // 海报id，新增时不填
        posterTemplateId: '', // 海报模板id，自定义时不填
        exclusiveShopName: '', // 专卖店名称
        exclusiveShopAddress: '', // 专卖店地址
        phone: '', // 电话
        posterUrl: '', // 海报文件路径
        posterUrlzdy: '',//自定义海报文件路径
        attachmentId: '',// 海标id
        attachmentIdzdy: '',//自定义
      },
      type: '',//当前页面
      rejectReason: '',
      detailInfo: {},
      editType:'',
      houseName:'',
      compressedImage:undefined,
      customPosterModal:false,
      zcPosterModal:false,
      zcUrl:''
    };

  },
  watch: {

  },
  computed: {
  },
  created() {


  },
  mounted() {
    this.getShopList()
    this.getHDType()
    this.type = this.$route.query.type
    this.editType = this.$route.query.editType
    if( this.type  == 'edit' || this.type  == 'view'||(this.type  == 'edit'&&this.type  == 'communityCompass')) {
      this.getDetailInfo(this.$route.query.id)
    }
    // 如果是从小区楼盘过来的 活动类型固定小区拓展（需要后端给一个小区拓展的id）
    if(this.type  == 'communityCompass' || ( this.type  == 'edit'&&this.editType == 'communityCompass' )){
      this.form.activityTypeId='14925767639'
      this.houseName =  this.$route.query.communityName

    }
  },
  methods: {
    // 点击模板打开弹窗
    moudleFoucus(){
      this.$refs.moudel.blur()
      if(!this.form.activityTypeId){
        this.$message.warning('请选择活动类型')
        return
      }
      if(!this.form.activityStartTime){
        this.$message.warning('请选择活动开始时间')
        return
      }
      this.visibleModal=true

    },
    getGJlIST(){
      // findFollowPeople({custCode:this.$store.state.user.userInfo.customer.customerCode}).then(res=> {
      //   console.log(res);
      // })
      let data = {custCode:this.$store.state.user.userInfo.customer.customerCode}
      return findFollowPeople(data)
    },
    selectAllDLMD(item) {
      let arr = [];
      item.DLMDList.forEach((gjItem) => {
        arr.push(gjItem.id);
      });
      item.DLMDName = arr;
      this.$forceUpdate()

    },
    // 清空 -- 清空select绑定的值
    clearAllDLMD(item) {
      item.DLMDName = [];
      this.$forceUpdate()
    },
    // 修改跟进人
    // handleChangeDLList(item,value,data) {
    //
    //   let length = data.length
    //   data.forEach(element => {
    //     // 当数组中存在0，说明此时进行全选/取消全选
    //     console.log(element == '0');
    //     if (element == '0') {
    //       // 当数组长度为最大长度且最后一个元素为0时，说明此时在全选的基础上又点击全选，则取消全选
    //       if (length == item.DLMDList.length && item.DLMDList[0] == '0') {
    //         item.DLMDName = []
    //       } else {
    //         item.DLMDName = []
    //         // 当不是取消全选操作，只要数组中出现了0则说明进行了全选操作
    //         for (let i in item.DLMDList) {
    //           item.DLMDName.push(item.DLMDList[i].code);
    //         }
    //       }
    //     }
    //   })
    //
    // },
    handleChangeDLList(item,value,data) {
      item.DLMDName = this.checkAll(data, item.DLMDList)
      this.$forceUpdate()
    },
    checkAll (arr, modelList) {
      // arr:change中的数组 ，  modelList:下拉框List
      let length = arr.length
      let list = arr
      arr.forEach(element => {
        // 当数组中存在0，说明此时进行全选/取消全选
        if (element == 'all') {
          // 当数组长度为最大长度且最后一个元素为0时，说明此时在全选的基础上又点击全选，则取消全选
          if (length - 1 == modelList.length && arr[length - 1] == '0') {
            list = []
          } else {
            // 当不是取消全选操作，只要数组中出现了0则说明进行了全选操作
            list = []
            for (let i in modelList) {
              list.push(modelList[i].code)
            }
          }
        }
      })
      return list
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    downloadByBlob(item) {
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = item.response.url
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob)
          this.download(url, item.name)
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    },
    download(href, name) {
      const eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    },
    // 获取详情
   async getDetailInfo(id){
      let data  = {
        activityId: id
      }
     await getDetail(data).then(res=>{
        if(res.data.code == 0) {
            this.detailInfo =  res.data.data
            this.form.activityId = res.data.data.id// 活动id 新增不填
            this.getShopList()
            this.form.shopInfoId =  String(res.data.data.shopInfoId)// 门店id
            this.form.activityTypeId =  String(res.data.data.activityLabelId) // 活动类别id
            this.form.activityTheme =  res.data.data.activityTheme // 主题
            this.form.activityStartTime= res.data.data.startTime // 开始时间
            this.form.activityEndTime= res.data.data.endTime // 结束时间
            this.form.modelType = Number(res.data.data.posterDTO.posterType)// 默认模板分类为通用
            this.form.posterId = res.data.data.posterDTO.id//海报id
            this.posterDTO.posterId = res.data.data.posterDTO.id

            this.exclusiveActivitySalesDTOList = res.data.data.saleInfoList

          if(res.data.data.houseName){
            this.houseName =  res.data.data.houseName
            this.houseId =  res.data.data.houseId?res.data.data.houseId:''
            this.$nextTick(()=>{
              let list = []
              findFollowPeople({custCode:this.$store.state.user.userInfo.customer.customerCode}).then(respone=> {
                list = respone.data.data
                this.exclusiveActivitySalesDTOList.forEach(item=>{
                  item.activitySalesId = item.id
                  item.DLMDList = list
                  item.DLMDName =  item.fellowPeople?item.fellowPeople.split(','):[]
                  item.activityPlanAmount = item.planSaleMoney
                  item.activityPlanGuest = item.activityPlanGuest?item.activityPlanGuest:0
                  this.$forceUpdate()
                  // if(item.DLMDName.length>0){
                  //   item.DLMDName.forEach(item=>{
                  //     item = string(item)
                  //   })
                  //
                  // }

                })
              })

            })
          } else {
            this.exclusiveActivitySalesDTOList.forEach(item=>{
              item.activityPlanAmount = item.planSaleMoney
              item.activitySalesId = item.id
            })
          }
          // 活动方案list
          this.activefileList = res.data.data.programmeAttachmentDtoList?res.data.data.programmeAttachmentDtoList:[]
          // 附件数据处理
          if(this.activefileList) {
            this.activefileList.forEach(item=>{
              item.url = item.attachPath
              item.name = item.attachName
            })
          }
          // 活动id
          // this.activefileId = res.data.data.programmeAttachmentDtoList.length>0?res.data.data.programmeAttachmentDtoList.map(it => it.id):[]
          this.activefileId = []
          if(res.data.data.programmeAttachmentDtoList.length > 0) {
            res.data.data.programmeAttachmentDtoList.forEach(item=>{
              this.activefileId.push({
                id: item.id
              })
            })
          }
          // 模板url
          // 通用
          if( this.form.modelType == 1) {
            this.modalItem = []
            this.posterDTO.posterUrl = res.data.data.posterDTO.activityPoster?res.data.data.posterDTO.activityPoster.attachPath:''
            this.posterDTO.attachmentId = res.data.data.posterDTO.activityPoster?res.data.data.posterDTO.activityPoster.id:''

            if(res.data.data.posterDTO.activityPoster) {
              this.modalItem.push(res.data.data.posterDTO.activityPoster)
              if(this.modalItem.length > 0) {
                 this.modalItem[0].posterTemplateId =  res.data.data.posterDTO.posterTemplateId
              }

            } else {
              this.modalItem = []
            }

            //通用的 获取接口的
            this.posterDTO.exclusiveShopName = res.data.data.posterDTO.specialStoreName
            this.posterDTO.exclusiveShopAddress = res.data.data.posterDTO.specialStoreAddress
            this.posterDTO.phone= res.data.data.posterDTO.phone
          } else {
            let data ={
              exclusiveShop:1
            }
            SalesShopInfoList( data ).then( res => {
              this.shopList = res.data && res.data.code == 0 ? res.data.list : []
              // 如果是自定义的  就获取第一个的的门店的数据
              let obj = this.shopList.find((i) => {
                return i.code == this.form.shopInfoId;//筛选出匹配数据
              });
              this.posterDTO.exclusiveShopName = obj&&obj.name? obj.name:''
              this.posterDTO.exclusiveShopAddress = obj&&obj.address? obj.address:''
              this.posterDTO.phone = obj&&obj.phone? obj.phone:''
            })

            this.posterDTO.posterUrlzdy = res.data.data.posterDTO.activityPoster?res.data.data.posterDTO.activityPoster.attachPath:''
            this.posterDTO.attachmentIdzdy = res.data.data.posterDTO.activityPoster?res.data.data.posterDTO.activityPoster.id:''
            // 回显自定义模板
            this.fileList = []
            if(res.data.data.posterDTO.activityPoster) {
              this.fileList.push(res.data.data.posterDTO.activityPoster)
              if(this.fileList.length > 0) {
                this.fileList.forEach(item=>{
                  item.url = item.attachPath
                  item.name = item.attachName
                  item.status = 'done'
                  item.thumbUrl = item.attachPath
                  item.percent = 100
                  item.uid = item.id
                })
              }
            } else {
              this.fileList = []
            }

          }
          if(res.data.data.posterDTO.posterType == '2'){
            this.form.model = '自定义'
          }else{
            this.form.model =  res.data.data.posterDTO.posterTemplateName
          }
          // 驳回原因
          this.rejectReason = res.data.data.rejectReason
        }
      })
    },
    // date为相加前的时间， days 为 需要相加的天数
    addDate(date, days) {
      var date = new Date(date);
      date.setDate(date.getDate() + days);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var mm = "'" + month + "'";
      var dd = "'" + day + "'";
      if(mm.length == 3) {
        month = "0" + month;
      }
      if(dd.length == 3) {
        day = "0" + day;
      }
      var time = year + "-" + month + "-" + day
      return time;
    },
    handleTimeChange(e, defaultTime) {
      this.form.activityStartTime = defaultTime;
      // 如果开始时间不为空 结束时间就加6天
      if(this.form.activityStartTime ) {
        this.form.activityEndTime = this.addDate(this.form.activityStartTime,6)
      } else {
        this.form.activityEndTime = ''
      }
    },
    // 开始时间选择不能选择今天之前的日期
    disabledDate(current) {
      return current && current < moment().endOf('day');
    },
    // 获取专卖店数据
    getShopList() {
      let data ={
        exclusiveShop:1
      }
      SalesShopInfoList( data ).then( res => {
        this.form.bussiness =res.data.custmerName
        this.shopList = res.data && res.data.code == 0 ? res.data.list : []
        if( this.shopList.length > 0 && this.type == 'add'){
          this.form.shopInfoId =  this.shopList[0].code
          this.getMarktlwuliao(this.form.shopInfoId)
          this.posterDTO.exclusiveShopName = this.shopList[0].name
          this.posterDTO.exclusiveShopAddress = this.shopList[0].address
          this.posterDTO.phone = this.shopList[0].phone
        }
      })
    },
    changeShop(e) {
      this.getMarktlwuliao(this.form.shopInfoId)
      let obj = this.shopList.find((i) => {
        return i.code == this.form.shopInfoId;//筛选出匹配数据
      });
      this.posterDTO.exclusiveShopName = obj.name
      this.posterDTO.exclusiveShopAddress = obj.address
      this.posterDTO.phone = obj.phone
      this.form.model = undefined // 重置活动模板
    },
    // 查询回显的物料组
   async getMarktlwuliao(id) {

     const  resP = await this.getGJlIST()
     let list =  resP.data.code == 0  ? resP.data.data  : []
     let data = {
       shopId: id
     }
      getMatklByShop(data).then(res => {
        if (res.data.code == 0) {
          this.exclusiveActivitySalesDTOList = res.data.data
          if(this.exclusiveActivitySalesDTOList.length > 0){
            this.exclusiveActivitySalesDTOList.forEach(item=>{
              item.activityPlanAmount = 0
              item.activitySalesId = ''
              item.materialGroupId = item.matklId
              item.activityPlanGuest = 0
              item.DLMDList = list
            })
          }
        }
      })
    },
    // 切换活动类型
    changeActivityType() {
      this.form.model = undefined // 重置活动模板
    },
    changeActivityPlanAmount(){
 
    },
    ActivityPlanAmountBlur(item,e){
     if(Number(e.target.value) > 999 || Number(e.target.value)  < 0) {
       this.$message.warning(`销售计划金额请输入0到999之间的数字`);
     }
      let value = Number(e.target.value)
      value = isNaN(value) ? "0" : value > 999 ? "999" : value % 1 === 0 ? value : value.toFixed(1);
      item.activityPlanAmount = value
      this.$forceUpdate()
    },
    //获取活动类别数据
    getHDType() {
      let data = {
        categoryName: 'specialActivityType'
      }
      getYxList(data).then(res => {
        this.activityTypeList = res.data.data
      })
    },
    // 打开编辑弹窗
    edit(){
      this.editModal = true

    },
    // 编辑的弹窗
    getModalEdit(form){
      this.posterDTO.exclusiveShopName = form.shopName
      this.posterDTO.exclusiveShopAddress = form.shopAdress
      this.posterDTO.phone = form.phone
      let data = {
        "shop":this.posterDTO.exclusiveShopName,
        "address":this.posterDTO.exclusiveShopAddress,
        "tel":this.posterDTO.phone,
        "date":this.form.activityStartTime + '~' + this.form.activityEndTime,
        actionId:this.type=='add'?null:this.form.activityId
      }
      let data1 = JSON.parse((this.modalItem[0].templateConfiguration));
      //  调用生成图片
      makeImg({ "type":1, actionId:this.type=='add'?null:this.form.activityId,jsonDto: data,json: data1}).then(res=>{
        if(res.data.code == 0) {
          this.posterDTO.posterUrl = res.data.data.url
        } else {
          this.$message.warning(res.data.msg)
        }
      })
    },
    delect(){
      this.modalItem = []
      this.posterDTO.posterUrl = ''
    },
    openViewTY(){
      this.modelViseibleTy = true
    },
    handleCancelmodelTy(){
      this.modelViseibleTy = false
    },
    timeChange(str,fomit){
      var arr = str.split(fomit);
      return arr[0]+'年'+arr[1]+'月'+arr[2]+'日';
    },
    viewOk(item){
      this.posterItem = item
      if(JSON.stringify(item) == "{}") {
        this.modalItem = []
      } else{
        const date = this.form.activityStartTime + '~' + this.form.activityEndTime
        const dateList = date.split('~')
        const dataStr = this.$util.formatDateNoFill(dateList[0], 'M月D日') + '至' + this.$util.formatDateNoFill(dateList[1], 'M月D日')
        // let data1 = JSON.parse((row.templateConfiguration));
        findTemplateDetail({templateId:item.posterTemplateId?item.posterTemplateId:item.id}).then(res=>{
          if(res.data.code == 0) {
            let data1 = JSON.parse((res.data.data.templateConfiguration));
            let data = {
              "shop":this.posterDTO.exclusiveShopName,
              "address":this.posterDTO.exclusiveShopAddress,
              "tel":this.posterDTO.phone,
              "date":dataStr,
            }
            //  调用生成图片
            makeImg({ "type":1,actionId:this.type=='add'?null:this.form.activityId,jsonDto: data,json: data1}).then(res=>{
              if(res.data.code == 0) {
                this.posterDTO.posterUrl = res.data.data.url
                this.visiblePoster=true
              } else {
                this.$message.warning(res.data.msg)
              }
            })
            // this.modalItem.push(item)
            // if(this.modalItem.length > 0) {
            //   this.modalItem[0].posterTemplateId =  item.id
            // }
          } else {
            this.$message.warning(res.data.msg)
          }
        })
      }

    },
    editShopInfo(){
      this.editModal = true
    },
    // 打开自定义海报
    openZDY(){
      this.customPosterModal=true
    },
    // 政策
    zcDetail(url){
      this.zcUrl=url
      this.zcPosterModal=true
    },
    // 确认使用
    confirmUse(row,url){
      this.modalItem=[]
      this.posterDTO.posterUrl = url
      this.modalItem.push(row)
      if(this.modalItem.length > 0) {
        this.modalItem[0].posterTemplateId =  row.id
      }

      this.form.model=this.modalItem[0].templateName
      this.visiblePoster=false
      this.visibleModal=false

    },
    // 自定义
    customPosterSumbit(modelfileId,posterUrlzdy,attachmentIdzdy,fileList){
      this.modelfileId = modelfileId
      this.posterDTO.posterUrlzdy = posterUrlzdy
      this.posterDTO.attachmentIdzdy = attachmentIdzdy
      this.fileList = fileList
      this.form.model='自定义'
      this.visibleModal=false
      this.modalItem=[]
    },
    // 自定义取消
    customPosterCancel(modelfileId,posterUrlzdy,attachmentIdzdy,fileList){
      // this.modelfileId = modelfileId ? modelfileId : ''
      // this.posterDTO.posterUrlzdy = posterUrlzdy ? posterUrlzdy : ''
      // this.posterDTO.attachmentIdzdy = attachmentIdzdy ? attachmentIdzdy  : ''
      // this.fileList = fileList?fileList : []
    },
    // 选中模板 获取模板中的数据
    getModalItem(row){
      const date = this.form.activityStartTime + '~' + this.form.activityEndTime
      const dateList = date.split('~')
      const dataStr = this.$util.formatDateNoFill(dateList[0], 'M月D日') + '至' + this.$util.formatDateNoFill(dateList[1], 'M月D日')
      findTemplateDetail({templateId:row.id}).then(res=>{
        if(res.data.code == 0) {
          let data1 = JSON.parse((res.data.data.templateConfiguration));
          let data = {
            "shop":this.posterDTO.exclusiveShopName,
            "address":this.posterDTO.exclusiveShopAddress,
            "tel":this.posterDTO.phone,
            "date":dataStr,
          }
          //  调用生成图片
          makeImg({ "type":1,actionId:this.type=='add'?null:this.form.activityId,jsonDto: data,json: data1}).then(res=>{
            if(res.data.code == 0) {
              this.posterDTO.posterUrl = res.data.data.url
            } else {
              this.$message.warning(res.data.msg)
            }
          })
          this.modalItem.push(row)
          if(this.modalItem.length > 0) {
            this.modalItem[0].posterTemplateId =  row.id
          }
        } else {
          this.$message.warning(res.data.msg)
        }
      })
      // if(JSON.stringify(row) == "{}") {
      //   this.modalItem = []
      // } else{
      //
      // }
    },
    openModalWindows() {
      if(!this.form.shopInfoId) {
        this.$message.warning('请先选择门店')
        return
      }
      if(!this.form.activityStartTime) {
        this.$message.warning('请先选择开始时间')
        return
      }
      this.visibleModal = true
    },
    // 下载图片到本地
    downs() {
      const link = document.createElement("a");
      link.href = this.downloadUrl;
      link.setAttribute("download", "活动模板.jpg");
      link.click();
    },

    // 预览
    openView(){
      this.modelViseible = true
    },
    handleCancelmodel() {
      this.modelViseible = false
    },
    goback(){
      if(this.$route.query.houseId){
        this.$router.push({
          path: "/communityCompass/list"
        });
      } else {
        this.$router.push({
          path: "/campaignList"
        });
      }

    },
    sumbit(){
      if(!this.form.shopInfoId){
        this.$message.warning('请选择门店')
        return
      }

      if(!this.form.activityTheme){
        this.$message.warning('请填写活动主题')
        return
      }
      if(!this.activefileList.length > 0){
        this.$message.warning('请上传活动方案')
        return
      }


      if(!this.form.model) {
        this.$message.warning('请选择活动模板')
        return
      }



      if(this.exclusiveActivitySalesDTOList.length > 0 ){
        this.$refs.selectVal[0].blur()
        for (let i = 0; i < this.exclusiveActivitySalesDTOList.length; i++) {
          let item = this.exclusiveActivitySalesDTOList[i]
          if (item) {
            if (this.houseName && !item.activityPlanGuest&&item.activityPlanGuest!==0) {
              this.$message.warning(`请输入获客`);
              return false
            }
            if (item.activityPlanAmount == null) {
              this.$message.warning(`请填写销售计划金额`);
              return;
            }
            if (item.activityPlanAmount != null && Number(item.activityPlanAmount) < 0 || Number(item.activityPlanAmount) > 999) {
              this.$message.warning(`销售计划金额请输入0到999之间的数字`);
              return false
            }
            if (this.houseName && (!item.DLMDName||item.DLMDName.length<=0) && ( Number(item.activityPlanAmount)>0||Number(item.activityPlanGuest) > 0)){
              this.$message.warning(`请选择跟进人`);
              return false
            }
            if(Number(item.activityPlanAmount)==0 && Number(item.activityPlanGuest) == 0) {
              item.fellowPeople = ''
            }
          }
        }
      }
      let list =  []
      if(this.exclusiveActivitySalesDTOList.length > 0 ) {
        list = this.exclusiveActivitySalesDTOList.map(el => {
          return {
            ...el,
            DLMDList:null,
            activityPlanGuest:el.activityPlanGuest ? el.activityPlanGuest : 0,
            fellowPeople: el.DLMDName&&el.DLMDName.length > 0 ? el.DLMDName.join(',') : '',
          }
        })
      }

      let data  = {
        houseId:this.$route.query.houseId ,
        houseTaskId:'',
        //活动id 新增不传 编辑传
        // this.type == 'add' ? '' : activityId
        "activityId": this.type == 'add' ? '' : this.form.activityId,
        "shopInfoId": this.form.shopInfoId?this.form.shopInfoId:'',
        "activityTypeId": this.form.activityTypeId?this.form.activityTypeId:'',
        "activityTheme": this.form.activityTheme,
        "activityStartTime": this.form.activityStartTime+' 00:00:00',
        "activityEndTime": this.form.activityEndTime+' 23:59:59',
        "activityAttachmentIdStr": this.activefileList.length > 0 ? this.activefileId.map(it => it.id).join(',') : '',
        "posterTypeFlag": this.modalItem.length>0 ?1:2,
        "exclusiveActivitySalesDTOList": list,
        "posterDTO": {
          "posterId": this.type=='edit'?this.posterDTO.posterId:'', // 海报id，新增时不填
          "posterTemplateId":this.modalItem.length>0 ? this.modalItem[0].posterTemplateId : '',//海报模板id，自定义时不填,
          "exclusiveShopName": this.posterDTO.exclusiveShopName,
          "exclusiveShopAddress": this.posterDTO.exclusiveShopAddress,
          "phone": this.modalItem.length>0?this.posterDTO.phone:'',
          "posterUrl": this.modalItem.length==0 ? this.posterDTO.posterUrlzdy:this.posterDTO.posterUrl,// 自定义海报url
          'attachmentId': this.modalItem.length==0  ? this.posterDTO.attachmentIdzdy:this.posterDTO.attachmentId,// 自定义海报id
        }
      }
      this.pageLoading = true
      createOrEdit(data).then (res =>{
        if(res.data.code == 0){
          this.pageLoading = false
          this.$message.success(`${this.form.activityTheme}已创建成功`,3)
          this.timer = setTimeout(() => {  //设置延迟执行
            this.$router.push({
              path: "/campaignList"
            });
          }, 2000);
        } else if(res.data.code !== 400){
          this.pageLoading = false
          this.$message.warning(res.data.msg)
        } else {
          this.pageLoading = false
        }
      }).catch(error =>{
        this.pageLoading = false
      })

    },
    // 修改活动时间
    onChange(date, dateString) {
    },
    templateChange(e){
      this.form.modelType = e.target.value
    },
    // 上传图片处
    beforeUpload2(file) {
      this.activefileList = [...this.activefileList, file];
      return false;
    },
    beforeUpload3(file,fileList) {
    return new Promise(async (resolve, reject) => {
    if (fileList?.length>5) {
      if (file.uid==fileList[fileList?.length-1].uid) {
        this.$message.warning('最多上传五个附件,请重新选择')
        return reject(false);
      }else{
        return reject(false);
      }
    }
      let compressResult=await this.compressionImage(file)
      const formData = new FormData();
      formData.append("file1", compressResult,compressResult.name);
      formData.append("fileType", "shopactivity");
      axios.post(this.uploadUrl, formData).then(res => {
        if (res.data.message == '上传成功') {
          let midToolObject={name:compressResult.name,size2:(compressResult.size/1024).toFixed(2),url:res.data.url}
          this.activefileList = [...this.activefileList, midToolObject]
          this.activefileId.push({
            id: res.data.businessId
          });
        }else{
          this.$message.warning("上传失败，请联系管理员");
        }
      })
        return reject(false);
    })
      return false
    },
    compressionImage (file) {
      return new Promise((resolve, reject) => {
        new ImageCompressor({
          file: file,
          quality: 0.6,
          maxWidth: file.width,
          maxHeight: file.height,
          redressOrientation: false,

          // 压缩前回调
          beforeCompress: function (result) {
            // console.log('压缩之前图片尺寸大小: ', result.size/1021+'K')
            // console.log('mime 类型: ', result.type)
          },

          success: function (result) {
            // console.log('压缩之后图片尺寸大小: ', result.size/1021+'K')
            // console.log('mime 类型: ', result.type)
            // console.log('实际压缩率： ', ((file.size - result.size) / file.size * 100).toFixed(2) + '%')
            // console.log('result@@@@@@',result);
            
            resolve(result)
          },
          error (e) {
            reject(e)
          }
        })
      })
    },
    
    compressImage (result) {
      let that=this
      let options = {
        quality: 0.7,
        maxWidth: 500,
        maxHeight: 500,
        mimeType: 'image/jpeg'
      }
      that.$compress(result, options).then(data => {
        that.compressedImage = data
      })
    },
    //上传状态改变
    handleChange(info) {
      this.activefileList = info.fileList;
      if (info.file.status === "done") {
        if (info.file.response.message == "上传成功") {
          this.activefileList = info.fileList;
          this.activefileId.push({
            id: info.file.response.businessId
          });
        } else {
          this.$message.warning("上传失败，请联系管理员");
        }
      }
    },
    downLoad(item) {
      if( item.url) {
        window.open(item.url , '_blank')
      } else {
        window.open(item.response.url , '_blank')
      }

    },
    handleRemove(file) {
      const index = file;
      const newFileList = this.activefileList.slice();
      newFileList.splice(index, 1);
      this.activefileList = newFileList;
      this.activefileId.splice(index, 1);
    },
    handleDownload(item) {
      window.open(item.url, '_blank')
    },

    // 自定义模板
    // // 上传图片处
    // beforeUpload(file) {
    //   if (file.size / 1024 <= 500) {
    //     this.fileList = [...this.fileList, file];
    //   } else {
    //     this.$message.info("图像超出500K,请重新上传");
    //   }
    //   return false;
    // },
    // //上传格式错误
    // errorAcceptfun(e, e1) {
    //   console.log(e, e1);
    // },
    // //上传状态改变
    // handleChange1(info) {
    //   this.fileList = info.fileList;
    //   if (info.file.status === "done") {
    //     if (info.file.response.message == "上传成功") {
    //       this.fileList = info.fileList;
    //       //赋值自定义模板Id
    //       this.modelfileId = info.file.response.businessId;
    //       this.posterDTO.posterUrlzdy = info.file.response.url
    //       this.posterDTO.attachmentIdzdy = info.file.response.businessId
    //     } else {
    //       this.$message.warning("上传失败，请联系管理员");
    //     }
    //   }
    //   if(info.file.status === "removed"){
    //     //删除置空
    //     this.modelfileId = '';
    //     this.posterDTO.posterUrlzdy = ''
    //   }
    // },
    // handleCancel() {
    //   this.previewVisible = false;
    // },
    // async handlePreview(file) {
    //   if (!file.url && !file.preview) {
    //     file.preview = await getBase64(file.originFileObj);
    //   }
    //   let data = {
    //     "type":2,
    //     "actionId":"",
    //     "account":"70811151026",
    //     "json":{
    //       "elements":[
    //         {"type":"bg",
    //           "imgSrc":this.posterDTO.posterUrlzdy ,
    //           "videoSrc":"",
    //           "left":0,
    //           "top":0,
    //           "width":"750",
    //           "height":"1624",
    //           "lineHeight":0,
    //           "animatedName":"",
    //           "duration":1,
    //           "delay":0,
    //           "playing":false,
    //           "loop":false,
    //           "opacity":100,
    //           "transform":0,
    //           "text":"",
    //           "textAlign":"left",
    //           "iconKey":"",
    //           "bg":"",
    //           "fontSize":36,
    //           "fontFamily":"微软雅黑",
    //           "fontWeight":"normal",
    //           "color":"#000000",
    //           "zindex":1}]},
    //           "jsonDto":{
    //             "shop":"",
    //             "address":"",
    //             "tel":"",
    //             "date":""
    //           }
    //   }
    //   this.pageLoading = true
    //   makeImg(data).then(res=>{
    //     if(res.data.code == 0) {
    //       this.previewImage = res.data.data.url
    //       this.previewVisible =  true
    //       this.pageLoading = false
    //     } else {
    //       this.$message.warning(res.data.msg)
    //       this.pageLoading = false
    //     }
    //
    //   })
    //   // this.previewImage = file.url || file.preview;
    //   // this.previewVisible = true;
    // },


  },
};
