<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelNameaaaaaa"
      :width="648"
      :closable="false"
      centered
      :maskClosable="false"
    >
        <div class="modal_close" @click="cancle">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
          <span class="modal_title">
            <i class="modal_title_line"></i>海报内容编辑
          </span>
        </div>
          <div class="cancelContent">
            <div class="selectBox">
              <div class="everyBox">
                <p class="lable"><span style="color:#D9161C">*</span>门店名称：</p>
                <a-input
                  style="width: 418px"
                  placeholder="请输入门店名称"
                  v-model="form.shopName"/>
              </div>
              <div class="everyBox">
                <p class="lable"><span style="color:#D9161C">*</span>门店地址：</p>
                <a-input
                  style="width: 418px"
                  placeholder="请输入门店地址"
                  v-model="form.shopAdress"/>
              </div>
              <div class="everyBox three">
                <p class="lable"><span style="color:#D9161C">*</span>联系电话：</p>
                <a-input
                  style="width: 418px"
                  placeholder="请输入联系电话"
                  v-model="form.phone">
                </a-input>
              </div>
            </div>
          </div>
    </a-modal>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
     form:{
       shopName:'',
       phone:'',
       shopAdress:'',
     },
      pageLoadFlag:false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    posterDTO:{
      type: Object,
      default: () => {}
    }
  },

  watch: {
    visible(val,old) {
      if(val) {
        this.form = {
          shopAdress:'',
          shopName:'',
          phone:'',
        }
        this.form.shopAdress = this.posterDTO.exclusiveShopAddress
        this.form.shopName = this.posterDTO.exclusiveShopName
        this.form.phone = this.posterDTO.phone
      }
    }



  },
  mounted() {


  },
  methods: {

    handleOk() {
      this.$emit("update:visible", false);
      this.$emit("cancelReasonHandOk", this.form);
      this.$forceUpdate()
    },
    cancle() {
      this.$emit("update:visible", false);
      this.$forceUpdate()

    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNameaaaaaa {
  .ant-input{
    border: 1px solid #D9D9D9!important;
  }
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 30px 0px 0 0px;

      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;


        .selectBox {
          //margin-top: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .everyBox {
            display: flex;
            align-items: center;
            //margin-left: 78px;
            margin-top: 24px;

            .lable {
              //width: 108px;
              margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;

              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
            }
            /deep/.ant-select-auto-complete.ant-select .ant-input {
              //border-width: 0px!important;
              width: 300px!important;
              border-color: #eee;;
            }
            .ant-select-auto-complete.ant-select .ant-input {
              width: 300px!important;
              border-color: #eee;;
            }
            .ant-select-selection--single {
              width: 300px;
            }

            /deep/ .ant-select-selection {
              width: 300px!important;
              height: 32px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
            }

            .aInput {
              width: 416px !important;
              height: 32px !important;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #D9D9D9;
              color: #262626;
            }

            .userInput {
              width: 200px;
              margin-right: 16px;
            }

            .radioGroup {
              margin-left: 8px;
            }

            .userSorce {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              //align-items: center;
              max-width: 530px;

              .smallSorce {
                padding: 0 14px 0 14px;
                color: #00AAA6;
                height: 32px;
                border-radius: 16px;
                border: 1px solid #EEEEEE;
                text-align: center;
                line-height: 32px;
                margin-right: 8px;
                margin-bottom: 12px;
                cursor: pointer;
              }

              .smallSorceActice {
                cursor: pointer;
                height: 32px;
                background: #00AAA6;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                padding: 0 14px 0 14px;
                color: #fff;
                margin-right: 8px;
                margin-bottom: 12px;
                //border: none;
              }
            }

            .circleBox {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 1px solid #EEEEEE;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 12px;
                height: 12px;
              }
            }

          }
          .three {
            /deep/ textarea.ant-input {
              border: 1px solid #D9D9D9;
            }
          }
          .everyBox:first-child{
            margin-top: 40px;
          }

          .proBox {
            display: flex;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start;

            .prooneBox {
              display: flex;

              .prouctBox {
                display: flex;
                flex-direction: column;

                .proItem {
                  display: flex;
                  margin-bottom: 16px;
                  align-items: center;

                  /deep/ .ant-select-selection {
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #EEEEEE;
                  }

                }
              }
            }

            .addNEWbTN {
              cursor: pointer;
              width: 180px;
              height: 32px;
              border-radius: 3px;
              border: 1px dashed #EEEEEE;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #1890FF;
              margin-left: 332px;

              img {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }
            }

          }

          .biaoqian {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .biaoqianBox {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start
            }

            .tips {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              margin-left: 109px;
              margin-top: -6px;
            }

          }
          .laiyuan {
            margin-top: 12px;
          }

          .adress {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div {
              display: flex;
            }

            .userDsInputress {
              margin-top: 16px;
              margin-left: 106px;
              width: 500px;
            }
          }
        }


        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }



      .modal_close {

        position: absolute;
        width: 42px;
        height: 42px;
        top: 0px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }

}

/deep/ .cancelNameYHLY {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      .cancelContent {
        .btnGroup {
          display: flex;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
        }

        display: flex;
        justify-content: center;
        width: 470px;

        .clickBtn {
          cursor: pointer;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 12px;
        }

        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 12px;
        }
      }

      .cancelInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        //margin-bottom: 36px;
        margin-top: 12px;
        text-align: left;
        overflow: hidden;

        .cancleZi {
          width: 56px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px
        }

        .ant-input {
          width: 300px!important;
          height: 32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}

/deep/ .cancelNameTips {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      .cancelContent {
        display: flex;
        flex-wrap: wrap;
        width: 418px;

        .clickBtn {
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 12px;
          cursor: pointer;
        }

        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 12px;
        }
      }

      .cancelInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        //margin-bottom: 24px;
        text-align: left;
        overflow: hidden;
        margin-top: 12px;

        .cancleZi {
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          margin-right: 8px;
          text-align: right;
        }

        .ant-input {
          width: 300px!important;
          height: 32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: -24px;
        right: -114px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


