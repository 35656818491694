<template>
  <a-modal
    v-model="visible"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="648"
    :closable="false"
    centered
    :maskClosable="false"
    :footer="null"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>自定义海报
      </span>
    </div>
    <div class="cancelContent">
      <div class="rightBox">
        <div class="selectBox">
          <div class="everyInput upload" >
            <span  class="labelTWO"><span>*</span>上传海报：</span>
            <!--自定义模板-->
            <div class="rightUpload">
              <a-upload
                name="file1"
                :data="{fileType:'shopactivity'}"
                list-type="picture-card"
                :file-list="fileList"
                :action="uploadUrl"
                @preview="handlePreview"
                @change="handleChange1"
                accept="image/png, image/jpeg, image/jpg"
                @reject="errorAcceptfun"
              >
<!--                && type !=='view'-->
                <div v-if="fileList.length < 1 ">
                  <a-icon style="font-size:26px;color:#aaa" type="plus" />
                </div>
              </a-upload>
              <a-modal class="previewVisibleA"  :maskClosable="false"   :closable="false"
                       :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <div class="modal_close" @click="previewVisible=false">
                  <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
                </div>
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
            <div class="outbox">
              <div class="exampleImg" >
                <img  src="https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/modelImg.jpg?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=3724505762&Signature=PJJYlOrMZJLapUWeIKnWI5SisnA%3D" alt="">
<!--                <span class="downLoad" @click="downs('@/assets/order/modelImg.jpg')">下载</span>-->
                <a>
                  <div class="mask">
                     <span>
                       <span class="view" @click="openView"><i class=" icon iconfont  icon-fujian-yulan" style="margin-right: 6px;"></i>预览</span>
                       <span class="view" @click="downs('https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/modelImg.jpg?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=3724505762&Signature=PJJYlOrMZJLapUWeIKnWI5SisnA%3D')"><i class=" icon iconfont  icon-fujian-xiazai"></i>下载</span>
                    </span>
                  </div>

                </a>
              </div>
            </div>
          </div>

          <p class="tips">
            使用说明：请下载样例结合活动进行
            修改，但小程序码的位置请勿改动，</p>
        </div>
      </div>
      </div>
      <a-modal
        class="previewVisibleA"
        :maskClosable="false"
        :closable="false"
        v-model="modelViseible"
        :footer="null"
        style="width:562.5px">
        <div class="modal_close" @click="modelViseible=false">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <img style=" width: 100%;"  src="https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/modelImg.jpg?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=3724505762&Signature=PJJYlOrMZJLapUWeIKnWI5SisnA%3D" alt="">
      </a-modal>

      <div class="btnGroup">
        <h-btn
          class="btn"
          :width="120"
          :height="40"
          fontSize="16"
          content="取消"
          background="#fff"
          level="1"
          style="background:#fff;border:1px solid #ccc;color: #777;text-shadow: none;box-shadow: none"
          borderRadius="4"
          border="none"
          @click="cancle"
        >
        </h-btn>
        <h-btn
          class="btn"
          :width="120"
          :height="40"
          fontSize="16"
          content="提交"
          background="#00AAA6"
          level="1"
          style="background:#00AAA6;border:none;margin-left: 24px;text-shadow: none"
          borderRadius="4"
          border="none"
          @click="sumbit"
        >
        </h-btn>
    </div>
  </a-modal>
</template>

<script>
import { makeImg } from "@/views/monopolyShopActivity/creatStoreActivities/api";
import { publicPath } from "@/common/constant";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name:'',
  data(){
    return{
      uploadUrl: publicPath + "/comm/method=uploadFile.nd",
      fileList: [], // 自定义模板附件
      modelfileId: '',  // 自定义模板id
      previewVisible: false, // 自定义预览的弹窗
      previewImage: '', // 自定义预览的图片
      modelViseible:false,
      posterUrlzdy:'',
      attachmentIdzdy:'',
      downloadUrl: `${process.env.BASE_URL}downLoadTemplate/modelImg.jpg`, // 自定义的可供下载的

    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },

    modelId:{
      type:String,
      default:''
    },
    posterUrl:{
      type:String,
      default:''
    },
    attachmentId:{
      type:String,
      default:''
    },
    fileListOld:{
      type:Array,
      default: () => []
    },

  },
  watch: {
    visible: {
      immediate: true,
      handler(newValue) {
        if(this.visible) {
          if(this.modelId){
            this.modelfileId = this.modelId
           }
          if(this.posterUrl){
            this.posterUrlzdy = this.posterUrl
          }
          if( this.attachmentId){
            this.attachmentIdzdy = this.attachmentId
          }
          if(this.fileListOld){
            this.fileList=this.fileListOld
          }

        }
      }
    },
  },
  mounted(){
  },
  methods:{
    openModalWindows() {

    },
    // 下载图片到本地
    downs() {
      const link = document.createElement("a");
      link.href = this.downloadUrl;
      link.setAttribute("download", "活动模板.jpg");
      link.click();
    },

    // 预览
    openView(){
      this.modelViseible = true
    },
    // 自定义模板
    // 上传图片处
    beforeUpload(file) {
      if (file.size / 1024 <= 500) {
        this.fileList = [...this.fileList, file];
      } else {
        this.$message.info("图像超出500K,请重新上传");
      }
      return false;
    },
    //上传格式错误
    errorAcceptfun(e, e1) {
      console.log(e, e1);
    },
    //上传状态改变
    handleChange1(info) {
      this.fileList = info.fileList;
      if (info.file.status === "done") {
        if (info.file.response.message == "上传成功") {
          this.fileList = info.fileList;
          //赋值自定义模板Id
          this.modelfileId = info.file.response.businessId;
          this.posterUrlzdy = info.file.response.url
          this.attachmentIdzdy = info.file.response.businessId
        } else {
          this.$message.warning("上传失败，请联系管理员");
        }
      }
      if(info.file.status === "removed"){
        //删除置空
        this.modelfileId = '';
        this.posterUrlzdy = ''
        this.attachmentIdzdy = ''
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      let data = {
        "type":2,
        "actionId":"",
        "account":"70811151026",
        "json":{
          "elements":[
            {"type":"bg",
              "imgSrc":this.posterUrlzdy ,
              "videoSrc":"",
              "left":0,
              "top":0,
              "width":"375",
              "height":"667",
              "lineHeight":0,
              "animatedName":"",
              "duration":1,
              "delay":0,
              "playing":false,
              "loop":false,
              "opacity":100,
              "transform":0,
              "text":"",
              "textAlign":"left",
              "iconKey":"",
              "bg":"",
              "fontSize":36,
              "fontFamily":"微软雅黑",
              "fontWeight":"normal",
              "color":"#000000",
              "zindex":1}]},
        "jsonDto":{
          "shop":"",
          "address":"",
          "tel":"",
          "date":""
        }
      }
      this.pageLoading = true
      makeImg(data).then(res=>{
        if(res.data.code == 0) {
          this.previewImage = res.data.data.url
          this.previewVisible =  true
          this.pageLoading = false
        } else {
          this.$message.warning(res.data.msg)
          this.pageLoading = false
        }

      })
      // this.previewImage = file.url || file.preview;
      // this.previewVisible = true;
    },
    // 预览海报 选中的海报数据
    cancle(){
      this.$emit("cancelBtn",this.modelfileId,this.posterUrlzdy,this.attachmentIdzdy,this.fileList);
      this.$emit('update:visible',false)
    },
    //
    sumbit(){
      if(!this.modelfileId){
        this.$message.warning('请上传海报')
        return
      }
      this.$emit("sumbit",this.modelfileId,this.posterUrlzdy,this.attachmentIdzdy,this.fileList);
      this.$emit('update:visible',false)
    },





  }
}
</script>
<style lang="less" scoped>
/deep/ .cancelName{
  .ant-modal-content{
    text-align:center;
    border-radius: 10px;
    .ant-modal-body{
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 24px 24px 16px 24px;
      .cancelContent{

        .rightBox {

          position: relative;
          padding-bottom:24px;
          width: 1188px;
          //min-height: 447px;
          background: #FFFFFF;
          .selectBox {
            flex-wrap: wrap;
            display: flex;
            padding: 24px 0 0 24px;
            .fileListUpload {
              display: flex;
              align-items: center;
            }
            .everyInput {
              display: flex;
              flex-direction: row;
              margin-bottom: 24px;
              .label  {
                text-align: right;
                width: 78px;
                display:flex;
                height: 24px;
                align-items: center;
                justify-content: flex-end;

                span {
                  color: #D9161C;
                }
              }
              .labelTWO {
                text-align: right;
                width: 115px;
                margin-top: 8px;
                span {
                  color: #D9161C;
                }
              }
              .ant-select-disabled {
                color: #ccc;
              }
              .val-wrap {
                /deep/  .ant-upload-list {
                  display:none!important;
                }
                // 附件样式
                .fileListBox {
                  display: flex;
                  flex-wrap: wrap;
                  width: 380px;
                  align-items: center;

                  .fileListItem {
                    margin: 16px 10px 0px 10px;
                    display: flex;
                    width: 290px;
                    height: 88px;
                    background: #F2F3F5;
                    border-radius: 4px;
                    border: none;
                    align-items: center;
                    .boxbox {
                      display: flex;
                      height: 62px;
                      width: 290px;
                      align-items: center;
                      justify-content: space-between;
                      .leftABox {
                        margin-left: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .filecontent {
                          height: 70px;
                          margin-left: 12px;
                          justify-content: center;
                          display: flex;
                          flex-direction: column;

                          .filename {
                            font-size: 14px;
                            font-weight: 400;
                            color: #999999;
                            line-height: 18px;
                            word-break: break-all;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //这儿的数字代表的就是你所需要实现效果的第N行
                            -webkit-box-orient: vertical;
                            text-align: left;
                            width: 150px;
                          }
                          div {
                            display: flex;
                            .size {
                              font-size: 14px;
                              font-weight: 400;
                              color: #AAAAAA;
                              margin-right: 10px;
                            }
                            .fineshedtip {
                              color: #00B7B3;
                              font-size: 14px;
                              //margin-left: 10px;
                            }
                            .openView {
                              cursor: pointer;
                            }

                          }
                        }
                      }
                      .iconfont{
                        margin-right: 12px;
                        color: #AAAAAA;

                      }
                      .iconfont:hover{
                        color: #00AAA6;
                        cursor: pointer;
                      }
                    }

                  }
                  .fileListItem:first-child{
                    margin-top: 0;
                  }
                }
              }


            }
            .everyInput:nth-child(2n){
              margin-left: 76px;
            }
            .upload {
              position: relative;
              margin-bottom: 0;
              width: 100%;
              .rightUpload {
                display: flex;
                flex-direction: column;
                position: relative;
                /deep/.ant-upload /deep/.ant-upload-select-picture-card{
                  width: 204px;
                  height: 272px;
                }
                .ant-upload-select-picture-card {
                  width: 204px;
                  height: 272px;
                  background: #FAFAFA;
                  border-radius: 4px;
                  border: 1px dashed #D9D9D9;
                }
                .ant-upload-list-item {
                  width: 204px;
                  height: 272px;
                }
                //.ant-upload-list-picture-card
                /deep/.ant-upload-list-item-info::before{
                  left: 0;
                }
                .ant-upload-list-item-info::before{
                  left: 0;
                }
                .ant-upload-list-picture-card .ant-upload-list-item-info::before{
                  left: 0;
                }
                .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o, .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download, .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete{
                  margin:0 8px;
                }
              }
              .outbox{
                width: 204px;
                height: 272px;
                background: #FAFAFA;
                border-radius: 4px;
                //border: 1px solid #D9D9D9;
                position: absolute;
                right: 88px;
                .exampleImg {
                  .downLoad {
                    font-size: 12px;
                    font-weight: 400;
                    color: #4285FF;
                    margin-top: 8px;
                    cursor: pointer;
                  }
                  position: absolute;
                  right: 0px;
                  //width: 204px;
                  //height: 272px;
                  //background: #262626;
                  border-radius: 4px;
                  //opacity: 0.58;
                  img {
                    width: 204px;
                    height: 272px;
                    border-radius: 4px;
                  }
                  .mask {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 204px;
                    height: 272px;
                    background: rgba(101, 101, 101, 0.6);
                    color: #ffffff;
                    opacity: 0;
                    span{
                      display: flex;
                      align-items: center;
                      justify-content: center
                    }
                  }
                  .mask .view {
                    z-index:1000;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 272px;
                  }
                  .view:first-child{
                    margin-right: 24px;
                  }
                }
                .exampleImg a:hover .mask {
                  opacity: 1;
                }
              }
              .exampleImgOne {
                .downLoad {
                  font-size: 12px;
                  font-weight: 400;
                  color: #4285FF;
                  margin-top: 8px;
                  cursor: pointer;
                }
                //position: absolute;
                right: 0px;
                width: 204px;
                height: 272px;
                //background: #262626;
                border-radius: 4px;
                //opacity: 0.58;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 4px;
                }
                .mask {
                  position: absolute;
                  top: 0;
                  left: 115px;
                  width: 204px;
                  height: 272px;
                  background: rgba(101, 101, 101, 0.6);
                  color: #ffffff;
                  opacity: 0;
                }
                .mask .view {
                  z-index:1000;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 272px;;

                }
              }
              .exampleImgOne a:hover .mask {
                opacity: 1;
              }

            }
            .tips {
              height: 17px;
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              line-height: 17px;
              position: absolute;
              bottom: 17px;
              left: 139px

            }
          }
          .topBox {
            width: 586px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background: linear-gradient(90deg, #FCF0DB 0%, #FFFFFF 100%);
            border: 2px solid #FFFFFF;
            img {
              width: 24px;
              height: 24px;
              margin-left: 24px;
            }
            span {
              margin-left: 6px;
              height: 22px;
              font-size: 16px;
              font-weight: 500;
              color: #262626;
              line-height: 22px;
            }
          }
        }
        display: flex;
        width: 648px;
        height: 337px;
        justify-content: center;
      }
      .btnGroup{
        margin-top: 40px;
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        padding-bottom:10px;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing:1px;
          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }
    p{
      font-size: 18px;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }
    .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:0px 0 24px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        font-size: 16px;
        color:#777;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
        margin-left:24px;
      }
    }
  }
}

</style>
<style lang="less" scoped>
.previewVisibleA {
  position: relative;
  z-index:20000;
  /deep/.ant-modal-content {
    border-radius: 8px!important;
    .ant-modal-body {
      padding: 42px;
    }
  }
  .modal_close {
    position: absolute;
    width: 42px;
    height: 42px;
    top: 0px;
    right: 0;
    line-height: 42px;
    border-radius: 0 10px 0 0;
    text-align: center;
    cursor: pointer;

    .modal_close_icon {
      color: #aaa;
      font-size: 16px;
    }
  }
  .modal_close:hover {
    background: #E4F8F7;

    .modal_close_icon {
      color: #08ABA8;
    }
  }
}
</style>

