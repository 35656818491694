<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="580"
    :closable="false"
    centered
    :maskClosable="false"
    :footer="null"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>政策详情
      </span>
    </div>
    <div class="cancelContent">
      <div class="modelBox">
        <div
          class="img"
        >
          <!--          <img :src="posterItem.templatePicUrl" alt="">-->
          <img :src="url" alt="">
        </div>
      </div>
      <div class="btnGroup">
        <h-btn
          class="btn"
          :width="120"
          :height="40"
          fontSize="16"
          content="我已知晓"
          background="#00AAA6"
          level="1"
          style="background:#00AAA6;border:none;margin-left: 24px;text-shadow: none"
          borderRadius="4"
          border="none"
          @click="cancle"
        >
        </h-btn>
      </div>

    </div>
  </a-modal>
</template>

<script>
import service from "@/utils/request";
import { findPosterTemplatePage } from "@/views/monopolyShopActivity/creatStoreActivities/api";
export default {
  name:'addUserSource-modal',
  data(){
    return{
      editItem:{}, // 选中的可以编辑的
      cherkId:'',
      list:[],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      modelName:''
    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
    posterItem: {
      type: Object,
      default: () => {}
    },
    url:{
      type: String,
      default: ''
    }

  },
  watch: {
    visible: {
      immediate: true,
      handler(newValue) {
        if(this.visible) {

        }
      }
    },
  },
  mounted(){
    // this.getData()
  },
  methods:{
    // 确认使用
    view(){
      this.$emit("cancelReasonHandOk", this.posterItem,this.posterUrl);
    },

    handleOk() {
      this.$emit('update:visible',false)

    },
    cancle(){
      this.$emit('update:visible',false)
    },
    editShopInfo(){
      this.$emit("editShopInfo");
    },

  }
}
</script>
<style lang="less" scoped>
/deep/ .cancelName{
  .ant-modal-content{
    text-align:center;
    border-radius: 10px;
    .ant-modal-body{
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 24px 24px 16px 24px;
      .cancelContent{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        .modelBox {
          margin-top: 16px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          margin-left: 12px;
          img{
            width: 228px;
            height: 406px;
            margin-bottom: 30px;
          }
        }
        .footer-pager {
          margin-top: 4px;
          margin-right: 14px;
        }


      }
      .cancelInfo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        margin-bottom: 24px;
        text-align: left;
        overflow: hidden;
        .cancleZi{
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          margin-right: 8px;
          text-align: right;
        }
        .ant-input{
          width: 300px;
          height:32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .ant-input-affix-wrapper{
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        padding-bottom:10px;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing:1px;
          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }
    p{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }
    .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:0px 0 24px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        font-size: 16px;
        color:#777;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
        margin-left:24px;
      }
    }
  }
}
</style>


